.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px;

    h3 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .textfield {
        width: 80%;
        margin-bottom: 20px;
    }

    .columns {
        margin: 0px;
        display: flex;
        align-items: top;
        justify-content: space-between;
        margin-bottom: 20px;

        .dataInput {
            display: flex;
            align-items: center;
            justify-content: left;
            //padding-bottom: 8px;
            font-size: 16px;
            height: 60px;
            label {
                width: 170px;
            }
        }

        .left {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            border-radius: 10px;
            width: 360px;
            margin-right: 20px;

        }

        .right {
            width: 250px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            border-radius: 10px;
        }
    }

}

