.main {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    /* padding: 6rem; */
    /* min-height: 100vh; */
    border: 1px solid black;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 850px;
        height: 680px;
        border-radius: 10px;
        color: black;
        background-image: url("../../public/adbg.jpg");
        background-size: cover;

        position: relative;
        overflow: hidden;

        h2 {
            font-size: 70px;
            margin-top: 20px;
        }

        .MuiButton-contained {
            //text-transform: none;
        }

        .topLeftItems {
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 130px;
            left: 30px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
    
                .title {
                    font-size: 10px;
                    font-weight: bold;
                    color: #999;
                    margin-top: 15px;
                    margin-bottom: 5px;
                }
    
                li {
                    display: flex;
                    align-items: center;
                    padding: 5px;
                    font-size: 30px;
                    .image {
                        width: 32px;
                        padding-right: 8px;
                    }
                }
            }

        }

        .buttonContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 250px;
            height: 250px;
            position: absolute;
            top: 190px;
            left: 500px;
            background-color: white;
            padding: 10px;
            border-radius: 50%;
            border: 8px solid #3070aa;

            .info {
                text-align: center;
                width: 230px;                
                margin-bottom: 20px;
                font-size: 20px;
            }
        }

        .bottomLeftItems {
            display: flex;
            justify-content: space-between;
            width: 380px;
            position: absolute;
            top: 400px;
            left: 30px;
            background-color: rgba(0, 0, 0, 0.348);
            border-radius: 5px;
            padding: 10px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 22px;
                    color: white;
                    padding: 0px;
                    margin: 0px;
                    padding-bottom: 10px;
                }
            }
        }

        .bottomRightItems {
            display: flex;
            position: absolute;
            align-items: center;
            bottom: 10px;
            right: 10px;
            z-index: 2;
            .contact {
                display: flex;
                align-items: center;
                border-left: 3px solid #A8292F;
                padding-left: 10px;
                margin-right: 20px;
                height: 80px;
            }
            .image {
            }
        }

        .bottomRightCircle {
            background-color: white;
            width: 700px;
            height: 700px;
            border-radius: 50%;
            color: white;
            display: flex;
            position: absolute;
            bottom: -540px;
            right: -220px;
        }
    }

}
  