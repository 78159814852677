// .MuiButtonBase-root {
//     padding: 0px !important;
// }



.solar {
    background-image: url("../../../public/adbg.jpg");
    background-size: cover;
    display: flex;
    height: 100vh;
    width: 100vw;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px;
    padding: 0px;

    h3 {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .textfield {
        width: 80%;
        margin-bottom: 20px;
    }

    .columns {
        margin: 0px;
        display: flex;
        align-items: top;
        justify-content: space-between;
        margin-bottom: 20px;

        .dataField {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: left;
            //padding-bottom: 8px;
            font-size: 16px;
            height: 60px;

            .dataFieldLabel {
                width: 250px;
                padding-right: 20px;
            }

            .dataFieldLabelRight {
                width: 70px;
                // padding-right: 20px;
            }

            .radio {
                padding-top: 2px;
                padding-bottom: 2px;
            }

        }

        .leftCol {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            border-radius: 10px;
            // width: 400px;
            // margin-right: 20px;
        }

        .rightCol {
            width: 300px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 10px;
            border-radius: 10px;
        }
    }

    
}

